body {
 max-width: 100vw;
 overflow-x: hidden;
 font-family: "Montserrat", sans-serif !important;
 /* font-weight: 600; */
}

.humburger {
 width: 45px;
 height: 45px;
 border-radius: 50px;
}

.humburger i {
 font-size: 25px;
}

.header-content {
 max-width: 530px;
}

.header-title {
 max-width: 390px;
}

.header-play {
 font-size: 55px;
}
/* .header-logo {
 position: absolute;
 left: 0px;
 top: 20px;
} */

.text-sm {
 font-size: 0.8em;
}

.separator {
 width: 30px;
 height: 1px;
}

.text-primary-light {
 color: #f8bf43;
}

.diabled-col .muted {
 opacity: 0.3;
}

.diabled-col {
 overflow: hidden;
}
.diabled-col::before {
 content: "";
 background-color: rgba(255, 255, 255, 0.1);
 width: 200%;
 height: 100%;
 position: absolute;
 top: 0px;
 left: 0px;
 z-index: -1;
 pointer-events: none;
}

.icon-holder {
 width: 100px;
 height: 100px;
 background-color: #f0f8fc;
 border-radius: 50%;
}
.tools-card {
 width: 225px;
}
.header-title2 {
 max-width: 600px;
}

.bottom-link {
 opacity: 0.5;
}

.steps-text {
 max-width: 500px;
}

.learn-container {
 max-width: 500px;
}

.icon-holder {
 height: 90px;
 width: 90px;
 background-color: #f0f8fc;
}

.tool-card {
 width: 200px;
}

.text-short {
 line-height: 1.2;
}

.check-icon {
 font-size: 13px;
}

.list-text {
 font-size: 0.8em;
}

.course-title {
 max-width: 230px;
}

.overflow-x-visible {
 overflow: visible;
}

.elevate {
 position: absolute;
 z-index: 1000;
}

.text-light {
 font-weight: 300;
}

.landing-page-bg {
 background-image: url(../img/landing_page_bg2.png);
 background-size: cover;
 background-position: center;
}

.hover-anim {
 transition: all 0.3s;
}

.hover-anim:hover {
 transform: scale(1.1);
}
.bg-black {
 background-color: #000;
}
.chest-section {
 background-image: url(../img/treasure_chest.png);
 background-position: center;
 background-size: contain;
 background-repeat: no-repeat;
}

.footer-logo {
 width: 120px;
}
.footer-text1 {
 max-width: 250px;
}

.text-md {
 font-size: 0.9em;
}

h1 {
 font-size: 30px !important;
}
