body {
  font-family: "Exo 2", sans-serif !important;
}
.main-page {
  min-height: 100vh;
  width: 100vw;
}

.text-section {
  max-width: 345px;
}
.intro-text {
  max-width: 625px;
}
.skip-text {
  line-height: 1.2;
  font-weight: 600;
}

.text-bronze {
  color: #cda640;
}

.text-gold {
  color: #cda640;
}

.card-input2 {
  border: 1px dashed #cda640;
  color: #cda640;
  background-color: transparent;
  /* font-weight: bold; */
  font-size: 17px;
  transition: all 0.3s;
  outline-style: solid;
  outline-color: transparent;
  outline-width: thin;
}

.card-input {
  border: none;
  color: gray;
  background-color: white;
  /* font-weight: bold; */
  font-size: 17px;
  transition: all 0.3s;
  outline-style: solid;
  outline-color: transparent;
  outline-width: thin;
  animation: glowing 4000ms infinite;
  border-radius: 4px;
}

.card-input:focus {
  animation: none;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px #cda640;
  }
  100% {
    box-shadow: 0 0 20px transparent;
  }
  60% {
    box-shadow: 0 0 20px #cda640;
  }
  100% {
    box-shadow: 0 0 -10px #cda640;
  }
}

.button-glow {
  animation: glowing 5000ms infinite;
}

.block-card {
  border: 1px solid rgba(255, 255, 255, 0.3);
  min-width: 185px;
  width: 185px;
}

.block-card::before {
  content: "";
  height: 109%;
  position: absolute;
  pointer-events: none;
  width: 94%;
  transform: translate(3%, -4.5%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  top: 0px;
  left: 0px;
}

.card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}

.main-logo {
  width: 95px;
}

.bg-gold {
  background-color: #cda640;
}

.btn-next {
  width: 250px;
}

.btn-next {
  max-width: 100%;
}
.total-section {
  max-width: 500px;
}
.side-title {
  max-width: 220px;
}

.side-subtitle {
  /* line-height: 1.5; */
  max-width: 340px;
  font-size: 14px;
}
.side-subtitle-right {
  /* line-height: 1.5; */
  max-width: 360px;
  font-size: 14px;
}
.text-small {
  font-size: 0.8em;
}
.bar-parent {
  max-width: 800px;
}

.e-bar {
  height: 33px;
}

.main-bar {
  max-width: 500px;
}

.bar-parent {
  max-width: 500px;
}
.bar {
  min-width: 57px;
}

.bg-silver {
  background-color: #778494;
}

.w-auto {
  width: auto !important;
}

.inputContainer {
  max-width: 135px;
}

.card-input:focus {
  outline-color: #cda640 !important;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn-add {
  cursor: pointer;
  transition: all 0.3s;
}
.btn-add:hover {
  background-color: #dedede;
}
.title-large {
  font-size: 1.3em;
}

.subtext-section {
  max-width: 420px;
}

.play-icon {
  font-size: 40px !important;
}

.slde2-main-logo {
  width: 100px;
}

.text-sm {
  font-size: 0.7em !important;
  line-height: 1.7;
}

.item-input {
  border: 1px solid #cda640;
  color: #cda640;
  background-color: transparent;
  /* font-weight: bold; */
  font-size: 17px;
  transition: all 0.3s;
  outline-style: solid;
  outline-color: transparent;
  outline-width: thin;
  /* font-size: 0.8em; */
  width: 130px;
}
.itm-lg {
  font-size: 1em;
}

.item-input::placeholder {
  color: #cda640;
  font-size: 0.8em;
}

.humburger {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1;
}

.humburger i {
  font-size: 22px !important;
}

.intro-title {
  font-size: 60px;
}

.indicator {
  width: 100px;
}

.play-icon {
  width: 40px;
}

.play-next {
  transition: all 0.3s;
}

.play-next:hover {
  transform: scale(1.1);
}

.step1-bg {
  background-image: url(../img/slide1_main_bg_.png);
  background-position: center;
  background-size: cover;
}
.step2-bg {
  background-image: url(../img/step1_bg.png);
  background-position: center;
  background-size: cover;
}
.indicator-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 10px;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.default-bg {
  background-image: url(../img/step2_bg.png);
  background-position: center;
  background-size: cover;
}

.text-xsm {
  font-size: 0.7em;
}

.card-badge {
  width: 50px !important;
  margin-top: -25px;
}

.slide-footer {
  bottom: 0px;
  background-color: rgba(60, 68, 76, 0.95);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  position: sticky !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.footer-btn {
  transition: all 0.3s;
  cursor: pointer;
}

.footer-btn:hover {
  transform: scale(1.05);
}

.text-md {
  font-size: 0.8em;
  font-weight: 600;
}

.leaf-icon-image {
  left: 50%;
  transform: translate(-50%, -18%);
  width: 295%;
}

.hexagon-fill {
  width: 160px;
  cursor: pointer;
  transition: all 0.3s;
}

/* .hexagon-fill:hover {
 transform: scale(1.05);
} */

.hexagon-text {
  z-index: 2;
  line-height: 1;
}

.item-name-input {
  width: 150px;
}

.btn-add {
  font-size: 0.8em !important;
  border-radius: 30px !important;
}

.btn-add i {
  font-size: 19px;
}

.hexagon {
  width: 160px;
  cursor: pointer;
  transition: all 0.3s;
}

.hexagon.active {
  transform: scale(1.05);
}

.hexagon:hover {
  transform: scale(1.05);
}

.hexagon-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #cda640;
}

.hexagon-badge {
  width: 160px;
  cursor: pointer;
  transition: all 0.3s;
}

.hexagon-badge:hover {
  transform: scale(1.05);
}

.hexagon-badge-icon {
  width: 50px;
  z-index: 20;
  margin-top: -30px;
}

.invest-text {
  max-width: 226px;
}

.checkbox_indicator {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 10px);
}

.checkbox_indicator.active::before.checkbox_indicator::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  transform: scale(1.3);
  left: 0px;
  border-radius: 50%;
}

.bar-indicator {
  position: absolute;
  right: 0px;
  height: 120%;
  width: 2px;
  bottom: 0px;
  /* transform: translateX(50%) */
}

.bar-indicator-sm {
  height: 107%;
  left: 0px;
  width: 2px;
  bottom: 0px;
}

.bar-indicator.left {
  left: 0px;
}

.intro-bar {
  max-width: 275px;
}
.intro-bar2 {
  max-width: 320px;
}

.bar-indicator::before {
  content: "";
  width: 11px;
  height: 11px;
  background-color: white;
  position: absolute;
  border-radius: 10px;
  transform: translate(-60%, -50%);
}
.left.bar-indicator::before {
  transform: translate(-40%, -50%);
}
.stepper-text-section {
  max-width: 500px;
}

.error .text-error {
  color: #ff7b4e !important;
}
.error .input-error {
  border-color: #ff7b4e !important;
}

.second-intro-text {
  width: 316px;
}

.section-img {
  height: 120px;
  object-fit: cover;
}

.sec-card {
  border-radius: 5px;
  overflow: hidden;
  max-width: 320px;
  display: inline-block;
  text-align: left !important;
  cursor: pointer;
  color: #333 !important;
}

.text-mute {
  opacity: 0.8 !important;
}

._modal {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 120vh;
  width: 120vw;
  margin-left: -10vw;
  margin-top: -10vh;
  background: rgba(53, 65, 78, 0.9);
  z-index: 1000;
  transition: all 0.5s;
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none;
}
.modal-inner {
  height: 100vh;
  width: 100vw;
  /* margin-left: 10vw;
  margin-top: 10vh; */
}

.modal-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}
.modal-card {
  background-color: whitesmoke !important;
  max-width: 400px;
}

.input-label {
  font-weight: 600;
  color: gray;
}

._modal.show {
  opacity: 1 !important;
  transform: scale(1) !important;
  pointer-events: all !important;
}

.cancel-btn {
  z-index: 1000;
  /* position: absolute; */
}

.icon-right {
  transform: scaleX(-1);
}

.play-icon {
  cursor: pointer;
}

.tab-buttons {
  position: absolute;
  top: 0px;
  left: 0px;
}

.error .text-error-muted {
  color: #adadad;
}

.limit .text-error-muted {
  color: #adadad;
}

.stepper-input {
  border: 1px dashed #cda640;
  color: #cda640;
  background-color: transparent;
  font-size: 17px;
  transition: all 0.3s;
  outline-style: solid;
  outline-color: transparent;
  outline-width: thin;
  /* font-size: 0.8em; */
  width: 70px;
}

.mute-buttons {
  opacity: 0.3;
  position: relative;
}

.mute-buttons::before {
  content: "";
  /* background-color: pink; */
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.hover-card {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(50%, -50%);
  transition: all 0.3s;
  background-color: #2a414d !important;
  width: 275px;
  z-index: 1000;
}

.hover-card:hover {
  transform: translate(-10%, -50%);
}

.hambuger.active .bars {
  opacity: 0;
}

.hambuger .clear {
  opacity: 0;
}
.hambuger.active .clear {
  opacity: 1;
}
.hambuger i {
  transition: all 0.5s;
}
nav {
  z-index: 1000;
}

.hexagon-link {
  text-decoration: none !important;
}

.hunt-input {
  font-size: 0.9em;
}

.hunt-item {
  position: relative;
}
.del-icon {
  /* display: none; */
  position: absolute;
  right: -30px;
  cursor: pointer;
}

.hunt-item:hover .del-icon {
  display: flex;
}

.result-text {
  max-width: 400px;
}

.primary-line-break {
  height: 2px;
  max-width: 200px;
  border-radius: 5px;
}
.result-input {
  max-width: 150px;
}

.hexagon-outline {
}

.hexagon .bg-image {
  position: absolute;
  top: -32px;
}

.hexagon .hexagon-fill {
  opacity: 0;
  transition: all 0.3s;
}

.hexagon.active .hexagon-fill {
  opacity: 1;
}

.hexagon:hover .hexagon-fill {
  transform: scale(1.05);
}

.hexagon-badge-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 62px;
}

.complete .hx-fill {
  display: none;
}

.hx-outline {
  display: none;
}
.complete {
  opacity: 0.5;
}

.complete .hx-outline {
  display: flex;
}
.header-logo {
  max-width: 150px;
  z-index: 1;
  top: 20px;
  left: 0px;
  position: absolute;
}

.option-btn.muted {
  opacity: 0.7;
  pointer-events: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.bars-column {
  /* min-height: 150px; */
}

.desc-text {
  max-width: 500px;
  font-weight: 400 !important;
}
.hexagon-text {
  font-size: 18px;
}

.percentage {
  white-space: nowrap;
  margin-left: 10px;
}

.video-cover {
  position: absolute;
  z-index: 1000;
}

.alert-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 20000;
  background: rgba(53, 65, 78, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.alert-modal.show {
  opacity: 1;
  pointer-events: all;
}

.alert-card {
  background-color: #2a414d;
  max-width: 350px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(53, 65, 78, 1);
}

.content {
  font-weight: 500;
}

.card-icon {
  width: auto !important;
}

.owl-dot span {
  transform: scale(0.7);
}

.owl-dot.active span::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border: 1px solid #869791 !important;
  transform: scale(2);
  border-radius: 50%;
  left: 0px;
  top: 0px;
}

.step1-card {
}
.navigation-buttons {
  position: sticky;
  bottom: 0px;
  left: 0px;
  z-index: 100;
}

.navigation-buttons .btn {
  width: 50vw;
  border-radius: 0px !important;
  margin: 0px;
}

.hover-card .layer2 {
  opacity: 0;
  transition: all 0.3s;
}

.hover-card .layer1 {
  position: absolute;
  transition: all 0.3s;
}

.hover-card:hover .layer1 {
  opacity: 0;
}

.hover-card:hover .layer2 {
  opacity: 1;
}

.hover-indicator {
  width: 15px;
}

.card-input::placeholder {
  color: grey;
}

.hover-card .card-input::placeholder {
  font-size: 0.8em;
}
.hover-card-center .card-input::placeholder {
  font-size: 0.8em;
}

.hover-card-center {
  background-color: #2a414d !important;
  width: 275px;
  z-index: 1000;
}

.hover-card-clear {
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 18px;
}

.link-mute {
  opacity: 0.5;
  pointer-events: none;
}

.brxw {
  line-height: 1.5 !important;
}
.brxtyy {
  font-size: 20px;
}

.article-header {
  background-image: url(../img/article_bg.jpg);
  background-position: center;
  height: 400px;
}
.article2-bg {
  background-image: url(../img/article2.jpg);
}

.red-text {
  color: #8c111a;
}

.article-text {
  font-size: 14px;
}

.article-parent {
  background-image: url(../img/article_bottom.png);
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: white;
}

.slide {
  min-height: 100vh;
  min-width: 100vw;
}

.cover {
  height: 100vh;
  overflow-y: scroll;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.slide-option {
  text-decoration: none !important;
}

.badge-icon-sm {
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translate(-50%, -50%);
  width: 20px;
}

.mute {
  opacity: 0.3;
  pointer-events: none;
}

.hover-circle {
  position: absolute;
  /* top: -30px;
 left: -30px; */
  top: 0px;
  width: 100vw;
  background-color: #26404b;
  z-index: 1000;
  border-bottom: 1px solid #d3a751;
}

.hhx-33 {
  /* max-width: 100px; */
}

.pjk3 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.hr-v {
  height: 75%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.section-button {
  background-color: rgba(118, 118, 118, 0.76);
}

.intro-savings {
  max-width: 400px;
}

.center_carret {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: pink;
  display: inline-block;
  display: none;
}

.bottom-text {
  width: 207px;
  line-height: 1;
}

.no-anim {
  animation: none !important;
  transition: none !important;
}

.toggle-button {
  background-color: white;
  color: "gey";
}

.btn-add2 {
  /* font-size: ; */
  border-radius: 30px !important;
}

.btn-add2 i {
  font-size: 22px;
}

.toggle-button-parent {
  height: 41px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  /* overflow: hidden; */
}

.amt-i {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.icon-option {
  min-width: 90px;
}

.contain {
  width: 300px;
}

.contain-lg {
  width: 420px;
}

.s4-option small {
  font-size: 14px;
  /* max-width: 290px; */
  cursor: pointer;
}

.gold-hr {
  max-width: 300px;
  width: 100%;
  height: 1px;
  background-color: #d3a751;
}

.item-parent {
  border-top: 1px solid #d3a751;
}

.item-parent .item {
  border-bottom: 1px solid #d3a751;
}

.dateSlider {
  background-color: rgba(255, 255, 255, 0.15);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  overflow-x: hidden;
  max-width: 100vw;
}

.slider-item {
  padding: 10px;
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 13px;
  text-align: center;
  min-width: 30px;
  opacity: 0.5;
}

.calendar-marker {
  position: absolute;
  left: 30px;
  height: 100%;
  width: 30px;
  padding-top: 3px;
}

.mark {
  height: 3px;
  width: 30px;
  background-color: #d3a751;
  padding: 0px;
}

.marker-text {
  font-size: 12px;
  font-weight: bold;
}

.slider-item.active {
  opacity: 1;
}
.brg {
  min-height: 350px;
  max-height: 350px;
}

.or-icon {
  left: 50%;
  top: 0px;
  transform: translate(-50%, -50%);
  /* box-shadow: 0px 0px 20px #2d3640; */
  /* background-color: #2d3640; */
}

.or-icon-sep {
  left: 50%;
  transform: translate(-50%, -50%);
  /* box-shadow: 0px 0px 20px #2d3640; */
  background-color: #2d3640;
  width: 80px;
  height: 3px;
}

.disable-card {
  opacity: 0.4;
  pointer-events: none;
}

.dsbb {
  opacity: 0.7 !important;
}
.swipe-text {
  opacity: 0.5;
}

.tab-option {
  background-color: #56626e;
  display: inline-block;
}
.tab-option.active {
  background-color: #3f4953;
}

.tab-option span {
  opacity: 0.7;
}

.tab-option.active span {
  opacity: 1;
}

.w-33 {
  width: 33%;
}

.rounded-right {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.rounded-left {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.tab {
  /* border-radius: 5px; */
  overflow: hidden;
}

.btn-sm-back {
  background-color: #264565;
}

.btn-sm-next {
  background-color: #2f5174;
}

.vertical-bar {
  background-color: #4f5c69;
}

.vertical-bar.needs {
  background-color: #4f5c69;
}

.vertical-bar.wants {
  background-color: #606d7b;
}

.vertical-bar.savings {
  background-color: #717e8d;
}

.v-p {
  height: 150px;
}

.v-p2 {
  height: 120px;
  min-width: 285px;
}

.mn {
  min-width: 30%;
}

.ead6af {
  color: #ead6af;
}

.total-anim {
  border-top: 1px solid #d3a751;
  border-bottom: 1px solid #d3a751;
  max-width: 345px;
}

.schedule-section {
  background-image: url(../img/sec2.jpeg);
  background-position: center;
  background-size: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-0 {
  opacity: 0;
}

.habit-title {
  width: 100px;
}
.habits {
  max-width: 500px;
}

.shadow-hexagon {
  animation-name: hexagon-glow;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  filter: blur(7px);
  opacity: 0.7;
}
.shadow-hexagon.delay {
  animation-delay: 2s;
}
.article-header-image {
  z-index: 0;
  object-fit: cover;
}

.article-header .container {
  /* z-index: 10; */
  position: sticky;
}

.bg-red {
  background-color: #8a0b0b !important;
}

@keyframes hexagon-glow {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.year-input {
  max-width: 300px;
}

.progress-parent {
  height: 4px;
  background-color: rgba(137, 142, 162, 0.5);
}

.progress-highlight {
  background-color: #97a4b2;
}

.slider-indicator {
  position: absolute;
  transform: translateX(-50%);
  width: 70px;
}

.slider-indicator .pointer {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: white;
  margin-top: -10px;
}

.slider-indicator .pointer::before {
  content: "";
  position: absolute;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d3a751;
}

.year-target {
  position: absolute;
  /* right: 0px; */
  top: -18px;
  font-size: 14px;
}

.estimate-description {
  background-color: #8ea4bc;
}

.text-gold-light {
  color: #efc471 !important;
}

.ln {
  line-height: 1;
}

.year-lowerLimit {
  left: 0px;
}
.year-upperLimit {
  right: 0px;
}

.range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  height: 4px;
  background-color: rgba(137, 142, 162, 0.5);
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #d3a751;
  cursor: pointer;
  border: 4.2px solid white;
}

.range-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4caf50;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  content: "";
  position: absolute;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d3a751;
}

.custom-divider {
  height: 1px;
  width: 100%;
  /* background-color: #d3a751; */
  max-width: 270px;
  position: relative;
}

.custom-divider::before {
  content: "";
  position: absolute;
  height: 1px;
  width: calc(50% - 15px);
  left: 0px;
  background-color: #d3a751;
}
.custom-divider::after {
  content: "";
  position: absolute;
  height: 1px;
  width: calc(50% - 15px);
  right: 0px;
  background-color: #d3a751;
}

.custom-divider img {
  position: absolute;
}

.monthly-savings {
  max-width: 350px;
}

.slider-row {
  overflow: hidden;
  max-width: 1000px;
}

.top-bar div {
  min-width: 80px;
}

.property-option {
  border: 1px dashed #cda640;
  min-width: 250px;
}

.property-icon {
  width: 30px;
}

.timeline {
  min-height: 300px;
  max-width: 500px;
}

.hrs2 {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.step-indicator {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  left: 100%;
  transform: translate(-50%, -50%) scale(0.5);
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  /* grid-template-areas: ". ." ". ." ". ."; */
}
.timeline::before {
  content: "";
  width: 2px;
  height: 100%;
  background-color: rgba(137, 142, 162, 0.5);
  position: absolute;
  left: 50%;
  top: 0px;
}

.step-indicator .ring {
  transform: scale(0.9);
  display: none;
}

.optw2:last-child .step-indicator {
  transform: translate(-50%, -50%) scale(0.85);
}

.optw2:last-child .ring {
  display: flex;
}

.yearSlider {
  background-color: rgba(255, 255, 255, 0.15);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  overflow-x: hidden;
  max-width: 100vw;
}

.yearSlider .slider-item {
  padding: 10px;
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 13px;
  text-align: center;
  min-width: 60px;
  opacity: 0.5;
  transition: opacity 2s;
}

.yearSlider .calendar-marker {
  position: absolute;
  left: 60px;
  height: 100%;
  width: 60px;
  padding-top: 3px;
}

.yearSlider .marker-text {
  margin-top: -2px;
}

.slider-item.active {
  opacity: 1;
}

.timeline-text {
  line-height: 1.2 !important;
}

.flip {
  transform: scaleX(-1);
}

.set-b {
  content: "";
  /* position: absolute; */
  background-color: #d3a751;
  width: calc(100% - 40px);
  box-sizing: border-box;
  height: 1px;
  bottom: -2px;
  left: 0px;
  z-index: 0;
  margin-left: 20px;
  margin-right: 20px;
  opacity: 0.5;
}

.set-end {
  content: "";
  /* position: absolute; */
  background-color: #717e8d;
  width: calc(100% - 40px);
  box-sizing: border-box;
  height: 5px;
  margin-top: -1px;
  z-index: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.rotate {
  animation: rotation 30s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: scale(0.9) rotate(0deg);
  }
  to {
    -webkit-transform: scale(0.9) rotate(359deg);
  }
}

.property-pointer {
  position: absolute;
  bottom: -18px;
}

.info-block.round {
  border-radius: 20px;
}

.brd {
  border-top: 1px solid #8ea4bc;
  border-bottom: 1px solid #8ea4bc;
}

.tmln div {
  min-width: 105px !important;
}

.brd-left {
  border-left: 1px solid #8ea4bc;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.brd-right {
  border-right: 1px solid #8ea4bc;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.rep-hex {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -53%);
  z-index: 1;
}

.rep-hex-icon {
  z-index: 10;
}

.rep-hex-text {
  margin-top: 35px;
}

.rep {
  width: 80px;
}

.tab-sm {
  border-radius: 10px;
  background-color: #8ea4bc;
  min-width: 40px;
  height: 40px;
}

.tab-title-main {
  max-width: 275px;
}

.top-title {
  width: 300px;
}

.res-title {
  border-top: 1px solid #d3a751;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tmlxl {
  max-width: 50px;
}

.check-icon {
  transform: scale(1.5);
}

.add-center-divider::before {
  content: "";
  height: 100%;
  width: 1px;
  left: 50%;
  position: absolute;
  background-color: white;
  opacity: 0.6;
  transform: translateX(-50%);
}

.habit-ring {
  max-width: 55px;
}

.w-250 {
  max-width: 250px;
}

.w-300 {
  max-width: 300px;
}

.contain-500 {
  max-width: 500px;
}

.bottom-hover {
  position: fixed;
  bottom: 0px;
}

.res-img {
  width: 50px;
}
