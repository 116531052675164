.hambuger-fund {
 position: absolute;
 right: 0px;
 bottom: 0px;
 z-index: 1;
}

.menu-panel {
 background-color: rgba(53, 65, 78, 1);
 position: fixed;
 width: 100vw;
 height: 100vh;
 top: 0px;
 /* transform: translate(100%); */
 box-sizing: border-box;
 transform: translateY(-100%);
 z-index: 900;
}

.nav-links {
 right: 0px;
 position: absolute;
 margin-top: 150px;
 list-style-type: none;
 /* text-align: right; */
 transform: scale(0.9);
}

.navlink {
 font-size: 30px;
 cursor: pointer;
 position: relative;
 margin: 10px;
}

.menu-container {
 position: relative;
}

.nav-after {
 position: absolute;
 color: #333;
 background: white;
 /* border-radius:20px; */
 left: 0px;
 /* clip: rect(0px, 45, 400, 0); */
 /* clip: rect(0px, 0px, 45px, 0px); */
 clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
 transition: all 0.5s;
 transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
 white-space: nowrap;
}

.navlink:hover .nav-after {
 clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
 /* clip: rect(0px, 400px, 45px, 0px); */
}

.nav-links-after {
 content: "";
 height: 100%;
 width: 2px;
 background: white;
 position: absolute;
 left: -20px;
}

.navlink {
 color: white !important;
 text-decoration: none !important;
}

.navlink .nav-after {
 color: #333 !important;
 z-index: 1000;
 position: absolute;
}

.background-logo {
 opacity: 0.1;
 width: 40%;
 position: absolute;
 top: 50%;
 left: 10%;
 transform: translateY(-50%);
 pointer-events: none;
}

.menu {
 display: inline-block;
 cursor: pointer;
}

.ba {
 height: 3px;
 width: 50px;
 display: block;
 margin-bottom: 10px;
 position: relative;
 background-color: #333;
 -moz-border-radius: 10px;
 -webkit-border-radius: 10px;
 border-radius: 10px;
 -moz-transition: 0.4s;
 -o-transition: 0.4s;
 -webkit-transition: 0.4s;
 transition: 0.4s;
}

.model-2.active .bar {
 background-color: white;
}

.model-2.active .bar:nth-of-type(1) {
 -moz-transform: translateY(13px) rotate(45deg);
 -ms-transform: translateY(13px) rotate(45deg);
 -webkit-transform: translateY(13px) rotate(45deg);
 transform: translateY(13px) rotate(45deg);
}

.model-2.active .bar:nth-of-type(2) {
 opacity: 0;
}

.model-2.active .bar:nth-of-type(3) {
 -moz-transform: translateY(-13px) rotate(-45deg);
 -ms-transform: translateY(-13px) rotate(-45deg);
 -webkit-transform: translateY(-13px) rotate(-45deg);
 transform: translateY(-13px) rotate(-45deg);
}

.model-2 .bar:nth-of-type(3) {
 margin: 0px;
}

.model-2 .bar:nth-of-type(1) {
 -moz-animation: rotateR 1.5s cubic-bezier(0.5, 0.2, 0.2, 1);
 -webkit-animation: rotateR 1.5s cubic-bezier(0.5, 0.2, 0.2, 1);
 animation: rotateR 1.5s cubic-bezier(0.5, 0.2, 0.2, 1);
}

.model-2 .bar:nth-of-type(2) {
 -moz-animation: fade 1.5s cubic-bezier(0.1, 0.8, 0.1, 1);
 -webkit-animation: fade 1.5s cubic-bezier(0.1, 0.8, 0.1, 1);
 animation: fade 1.5s cubic-bezier(0.1, 0.8, 0.1, 1);
}

.model-2 .bar:nth-of-type(3) {
 -moz-animation: rotateL 1.5s cubic-bezier(0.5, 0.2, 0.2, 1);
 -webkit-animation: rotateL 1.5s cubic-bezier(0.5, 0.2, 0.2, 1);
 animation: rotateL 1.5s cubic-bezier(0.5, 0.2, 0.2, 1);
}

.nav-bar {
 height: 145px;
 position: relative;
}

.nav-bar .txt {
 color: #333;
}

.nav-bar-light .txt {
 color: #fff;
}

.nav-bar-light .bar {
 background-color: white;
}

.nav-bar-light {
}

.nav-bar .logo-white {
 display: none;
}

.nav-bar-light .logo-white {
 display: block;
}

.nav-bar-light .logo-dark {
 display: none;
}

.menu-panel .nav-bar {
 left: 50%;
 transform: translateX(-50%);
}

.m-h-100 {
 max-height: 100vh;
}

.position-absolute {
 position: absolute;
}

.m-w-100 {
 max-width: 100vw;
}

.curtain {
 height: 100vh;
 width: 100vw;
 pointer-events: none;
 background-color: white;
 z-index: 10000;
 transition: all 1s;
 position: fixed;
 top: 0px;
 opacity: 0;
}

.curtain.hidden {
 opacity: 0;
}
