@font-face {
  font-family: "Naskh-Nay";
  src: url("/fonts/Naskh-Nay.ttf.woff") format("woff"),
    url("/fonts/Naskh-Nay.ttf.svg#Naskh-Nay") format("svg"),
    url("/fonts/Naskh-Nay.ttf.eot"),
    url("/fonts/Naskh-Nay.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

.tf {
  font-family: "Naskh-Nay" !important;
}
