@media only screen and (max-width: 600px) {
  .intro-title {
    font-size: 30px !important;
  }
  .login-btn {
    display: none;
  }
  .skip-text {
    font-size: 14px;
    font-weight: 400;
  }
  .skip-text small {
    font-size: 12px;
  }
  .skip-text.text-sm {
    font-size: 12px !important;
  }
  .intro-text {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .play-icon {
    width: 40px;
  }
  .header-logo {
    display: none;
    width: 100px !important;
    left: 20px !important;
  }
  .modal-inner {
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .stp2 {
    margin-top: -65px;
  }
  .hexagon-fill {
    width: 140px;
  }
  .stp1 {
    margin-bottom: 40px !important;
  }
  .title-text {
    font-size: 22px;
  }
  .d-xsm {
    display: none !important;
  }

  .item-flat {
    width: 165vw;
  }
  .calto-logo {
    width: 100px;
    margin-top: -80px !important;
    margin-bottom: 30px;
  }

  .item-flat-parent {
    overflow-x: scroll;
  }
  .item-flat-parent {
    overflow-x: scroll;
    max-width: 95vw !important;
  }
  .bars-column {
    min-height: 150px;
  }

  .adjust-parent {
    width: 95vw;
    overflow-x: scroll;
  }
  .adjust {
    width: 218vw;
  }
  .d-n {
    display: none;
  }
  .slide-footer {
    box-shadow: none;
  }
  .navlink {
    font-size: 1.45em !important;
  }
  .card-scroll {
    overflow-x: scroll;
  }
  .desc-text {
    padding-left: 20px;
    padding-right: 20px;
  }
  .landing-title {
    font-size: 25px !important;
  }
  .dxx-2 {
    font-size: 0.9rem;
  }

  .bbtlxy {
    font-size: 25px;
  }

  .nav-padd {
    margin-top: 25px;
  }

  .hf-22 {
    width: 105px !important;
  }

  .bar-indicator::before {
    content: "";
    width: 11px;
    height: 11px;
    background-color: white;
    position: absolute;
    border-radius: 10px;
    transform: translate(-50%, -50%);
  }

  .landing-page-bg {
    height: 100vh;
  }

  .imageB {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .card-fill {
    /* min-width: 100vw; */
    padding-left: 20px;
    padding-right: 20px;
  }

  .ratio-text {
    width: 195px;
  }

  .input-sect {
    width: 275px;
  }

  .w-250 {
    max-width: 250px;
  }

  .info-block {
    background-color: #8ea4bc;
  }
  .contain {
    width: 300px;
  }

  .contain-lg {
    width: 300px;
  }
  .d-sm-none {
    display: none;
  }
  .intro-savings {
    max-width: 280px;
  }

  .rep {
    min-width: 50%;
    margin-bottom: 50px;
  }

  .rep-wrap {
    max-width: 500px;
  }
}
.info-block {
  background-color: #8ea4bc;
  /* max-width: 300px; */
}
